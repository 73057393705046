import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  setCategories,
  setBrands,
  setLowPrice,
  setHighPrice,
} from "../store/slices/cacheSlice";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

// components
import { axiosInstance } from "../libries";
import Layout from "../containers/Layout";
import Breadcrumb from "../components/Breadcrumb";
import BestBrand from "../components/BestBrand";
import LimtedOffer from "../components/LimitedOffer";
import ProdCategory, { ProdCategoryLoader } from "../components/prodCategory";
import { BannerCategory } from "../components/Banners";
import Empty from "../components/Empty";
import SignLogin from "../components/SignLogin";

// imgs
import limtedbanner from "../assets/imgs/banners/ipad-banner.png";
import { useSearchParams } from "react-router-dom";
import CartNotification from "../components/CartNotification";

const Category = () => {
  const dispatch = useDispatch();
  const [prods, setProds] = useState([]);
  const { brands, categories, minProdPrice, maxProdPrice } = useSelector(
    (state) => state.cache
  );

  const token = useSelector((state) => state.auth.accessToken);

  const myCurrentLocation = window.location.pathname;

  const [closePopup, setClosePopup] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isloading, setIsLoading] = useState(true);

  const [links, setLinks] = useState(null);

  const [totalProds, setTotalProds] = useState(null);

  const [lastPage, setLastPage] = useState(null);

  const [catBanners, setCatBanners] = useState(null);

  const [prodNotFoundBanner, setProdNotFoundBanner] = useState(null);

  // state to catch network error
  const [networkError, setNetworkError] = useState(false);

  // state to show the notification on categories page
  const [showCartNotify, setShowCartNotify] = useState(false);
  const [cartStatus, setCartStatus] = useState("");
  const [cartMessage, setCartMessage] = useState("");

  // declaring searchparams variables here
  const selBrands = searchParams.get("brands") || "";
  const selCategory = searchParams.get("categories") || null;
  const selDiscount = searchParams.get("discount") || "";
  const selMinVal = searchParams.get("min_val") || "";
  const selMaxVal = searchParams.get("max_val") || "";
  const searchProd = searchParams.get("prod") || "";
  const selFilter = searchParams.get("filter") || "all";
  const selPage = searchParams.get("page") || "1";

  const selectedBrand = selBrands.split(",");

  const updateSearchParams = useCallback(
    (key, newValue) => {
      setSearchParams((sp) => {
        sp.set(key, newValue);

        return sp;
      });
    },
    [setSearchParams]
  );

  const deleteSearchParams = (key) => {
    setSearchParams((sp) => {
      sp.delete(key);

      return sp;
    });
  };

  const filterBrand = (id, e) => {
    let currentBrands = [...(selBrands ? selBrands.split(",") : [])];

    const val = String(id);

    if (e.target.checked) {
      if (!currentBrands.includes(val)) currentBrands.push(val);
    } else {
      if (currentBrands.includes(val))
        currentBrands = currentBrands.filter((brnd) => brnd !== val);
    }

    if (currentBrands.length === 0) {
      deleteSearchParams("brands");
    } else {
      updateSearchParams("brands", currentBrands.join(","));
      updateSearchParams("page", "1");
    }
  };

  const filterDiscount = (e) => {
    if (e.target.checked) {
      if (searchParams.get("discount") === String(e.target.value)) {
        deleteSearchParams("discount");
        e.target.checked = false;
      } else {
        updateSearchParams("discount", e.target.value);
      }
    }
    updateSearchParams("page", "1");
  };

  // search endpoint
  useEffect(() => {
    if (searchProd === "") return;
    const url = `/search-and-filter-products?search_term=${searchProd}&discount=${selDiscount}&brands=${selBrands}&min_price=${selMinVal}&max_price=${selMaxVal}&sorting_key=${selFilter}`;
    setIsLoading(true);
    axiosInstance(token)
      .get(url)
      .then((resp) => {
        setIsLoading(false);
        setNetworkError(false);

        const res = resp.data.data.products;

        if (!Array.isArray(res)) {
          setProds(res.data);
          setLinks(res.meta.links);
          setLastPage(res.meta.last_page);
          setTotalProds(res.meta.total);
        } else setProds([]);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.code === "ERR_BAD_REQUEST") {
          setCartMessage(err.response.data.message);
        } else {
          setNetworkError(true);
          setCartMessage(err.message);
        }
      });
  }, [
    selBrands,
    selDiscount,
    selMinVal,
    selMaxVal,
    searchProd,
    selFilter,
    token,
  ]);

  // categories endpoint
  useEffect(() => {
    if (searchProd !== "") return;
    const url = `/products/all/category/${selCategory}?key=${selFilter}&discount=${selDiscount}&brand=${selBrands}&min_price=${selMinVal}
        }&max_price=${selMaxVal}&page=${selPage}`;

    setIsLoading(true);
    axiosInstance(token)
      .get(url)
      .then((resp) => {
        setIsLoading(false);
        setNetworkError(false);
        const res = resp.data.data.products;

        if (!Array.isArray(res)) {
          setProds(res.data);
          setLinks(res.meta.links);
          setLastPage(res.meta.last_page);
          setTotalProds(res.meta.total);
        } else setProds([]);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.code === "ERR_BAD_REQUEST") {
          setCartMessage(err.response.data.message);
        } else {
          setNetworkError(true);
          setCartMessage(err.message);
        }
      })
      .finally(() => {});
  }, [
    token,
    searchProd,
    selBrands,
    selCategory,
    selDiscount,
    selFilter,
    selMinVal,
    selMaxVal,
    selPage,
  ]);

  useEffect(() => {
    axiosInstance()
      .get("/categories")
      .then((resp) => {
        const categoriesResp = resp.data.data.category;
        dispatch(setCategories(categoriesResp));
      })
      .catch((err) => {
        return err.response;
      });
  }, [dispatch]);

  useEffect(() => {
    axiosInstance()
      .get("/brands")
      .then((resp) => {
        const brandResp = resp.data.data.brands;
        dispatch(setBrands(brandResp));
      })
      .catch((err) => {
        return err.response;
      });
  }, [dispatch]);

  // getting price range here
  useEffect(() => {
    axiosInstance()
      .get(`/products/filter/amount/ranges`)
      .then((resp) => {
        const price = resp.data.data;
        dispatch(setLowPrice(price.min_amount));
        dispatch(setHighPrice(price.max_amount));
      })
      .catch((err) => {
        return err.message;
      });
  }, [dispatch]);

  const [clickFilter, setClickFilter] = useState(false);

  const [showResultMobile, setShowResultMobile] = useState(false);

  const handleClearAll = () => {
    setValues([minProdPrice, maxProdPrice]);
  };

  const [values, setValues] = useState([minProdPrice, maxProdPrice]);

  const handleChange = (newValues) => {
    setValues(newValues);
  };

  useEffect(() => {
    if (
      selCategory !== null ||
      selBrands?.length >= 1 ||
      selDiscount !== "" ||
      values[0] > minProdPrice ||
      values[1] < maxProdPrice
    ) {
      setShowResultMobile(true);
    }
  }, [selCategory, selBrands, selDiscount, values, minProdPrice, maxProdPrice]);

  // banners for category page
  useEffect(() => {
    axiosInstance()
      .get(`/banner/category-page`)
      .then((resp) => {
        const res = resp.data.data.banners;
        setCatBanners(res);
      })
      .catch((_err) => {});
  }, []);

  // product not available banners
  useEffect(() => {
    axiosInstance()
      .get(`banner/product-request-banner`)
      .then((resp) => {
        const res = resp.data.data.banners;
        setProdNotFoundBanner(res);
      })
      .catch((_err) => {});
  }, []);

  useEffect(() => {
    if (categories) {
      const activeCate = categories.find(
        (cate) => searchParams.get("categories") === String(cate.id)
      );
      activeCate
        ? (document.title = `ShopNig - ${activeCate.name}`)
        : (document.title = `ShopNig - Category`);
    } else document.title = `ShopNig - Category`;
  }, [categories, searchParams]);

  return (
    <>
      <Layout>
        <div className="hide__breadcrumb__mobile">
          <Breadcrumb name="Home > Categories > Computer" />
        </div>
        <div className="category__pad">
          <BannerCategory catBanners={catBanners} />

          <div className="category">
            <div className="container">
              <div className="add_popup"></div>
              <div className="category__wrap">
                <div
                  className={`${
                    clickFilter ? "category__aside--mobile" : ""
                  } category__aside`}
                >
                  <div className="category__aside-contain">
                    <header className="category__header">
                      <div className="category__headerItems back">
                        <span className="arrow-back-outline">
                          <ion-icon
                            name="arrow-back-outline"
                            onClick={() => setClickFilter(false)}
                          ></ion-icon>
                        </span>
                      </div>
                      <div className="category__headerItems filter">
                        <p>Filter</p>
                      </div>
                      <div className="category__headerItems clear">
                        <Link to="/categories">
                          <p onClick={handleClearAll}>clear all</p>
                        </Link>
                      </div>
                    </header>
                    {!categories ? (
                      <FilterLoader />
                    ) : (
                      <div className="prodOverview">
                        <h3>List of categories</h3>
                        <ul className="prodOverview__listing">
                          <li
                            className={`prodOverview__item ${
                              searchParams.get("categories") === null
                                ? "active"
                                : null
                            }`}
                            onClick={() => {
                              if (searchProd) {
                                deleteSearchParams("prod");
                              }
                              deleteSearchParams("categories");
                            }}
                          >
                            <span>All Categories</span>
                            <span>{""}</span>
                          </li>
                          {categories?.map(({ name, id, is_visible }) => (
                            <div key={id}>
                              {is_visible === 1 ? (
                                <li
                                  className={`prodOverview__item ${
                                    searchParams.get("categories") ===
                                    String(id)
                                      ? "active"
                                      : null
                                  }`}
                                  onClick={() => {
                                    if (searchProd) {
                                      deleteSearchParams("prod");
                                    }
                                    updateSearchParams("categories", id);
                                    updateSearchParams("page", "1");
                                  }}
                                >
                                  <span>{name}</span>
                                  <span>{""}</span>
                                </li>
                              ) : null}
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                    {!brands && brands ? (
                      <FilterLoader />
                    ) : (
                      <div className="filterBrand">
                        <h3 className="filterBrand__header">Brand</h3>
                        <ul className="filterBrand__listing">
                          {brands &&
                            brands?.map(({ name, id }) => (
                              <li className="filterBrand__item" key={id}>
                                <div className="form_group">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => filterBrand(id, e)}
                                    checked={selectedBrand.includes(
                                      id.toString()
                                    )}
                                  />
                                  <label htmlFor="null">{name}</label>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                    <div className="filterDiscount">
                      <div className="filterDiscount__header">
                        {/* react slider sample */}
                        {maxProdPrice ? (
                          <div className="filterDiscount__range">
                            <div className="price__range">
                              <span>
                                Price (<s>N</s>)
                              </span>
                              <span
                                className="apply__range"
                                onClick={() => {
                                  updateSearchParams("min_val", values[0]);
                                  updateSearchParams("max_val", values[1]);
                                  updateSearchParams("page", "1");
                                }}
                              >
                                Apply
                              </span>
                            </div>
                            <Slider
                              range
                              min={minProdPrice}
                              max={maxProdPrice}
                              value={values}
                              onChange={handleChange}
                              allowCross={false} // Prevent handles from crossing
                              pushable={false} // disallow handles to be pushed beyond each other
                            />

                            <div className="price__range__input">
                              <input
                                type="text"
                                className="input__min"
                                value={values[0]}
                                disabled
                              />
                              <div className="separator">-</div>
                              <input
                                type="text"
                                className="input__max"
                                value={values[1]}
                                disabled
                              />
                            </div>
                          </div>
                        ) : null}
                        <ul className="filterDiscount__percentage">
                          <h3>Discount Percentage</h3>
                          <li className="filterDiscount__percentageItem">
                            <input
                              type="radio"
                              value="40"
                              name="discount"
                              id="discount"
                              checked={searchParams.get("discount") === "40"}
                              onClick={(e) => filterDiscount(e)}
                              onChange={() => {}}
                            />{" "}
                            40% or more
                          </li>
                          <li className="filterDiscount__percentageItem">
                            <input
                              type="radio"
                              value="30"
                              name="discount"
                              id="discount"
                              checked={searchParams.get("discount") === "30"}
                              onClick={(e) => filterDiscount(e)}
                              onChange={() => {}}
                            />{" "}
                            30% or more
                          </li>
                          <li className="filterDiscount__percentageItem">
                            <input
                              type="radio"
                              value="20"
                              name="discount"
                              id="discount"
                              checked={searchParams.get("discount") === "20"}
                              onClick={(e) => filterDiscount(e)}
                              onChange={() => {}}
                            />{" "}
                            20% or more
                          </li>
                          <li className="filterDiscount__percentageItem">
                            <input
                              type="radio"
                              value="10"
                              name="discount"
                              id="discount"
                              checked={searchParams.get("discount") === "10"}
                              onClick={(e) => filterDiscount(e)}
                              onChange={() => {}}
                            />{" "}
                            10% or more
                          </li>
                        </ul>
                      </div>
                    </div>
                    {showResultMobile && (
                      <div
                        className="show__result"
                        onClick={() => setClickFilter(false)}
                      >
                        <button className="btn__result">Show Result</button>
                      </div>
                    )}
                    <LimtedOffer src={limtedbanner} />
                  </div>
                </div>
                <div className="category__main">
                  <div className="heading__categories">
                    {searchProd === "" ? (
                      <h3 className="category__allProd">
                        <span>
                          {document.querySelector(".prodOverview__item.active")
                            ?.innerText ?? "All"}
                        </span>{" "}
                        {!isloading && prods.length ? `(${totalProds})` : null}
                      </h3>
                    ) : (
                      <h3 className="category__allProd">
                        <span>Search Results</span>{" "}
                        {!isloading && prods.length ? `(${totalProds})` : null}
                      </h3>
                    )}
                    {searchParams.get("prod") !== null && prods ? (
                      <span
                        className="clear__search__result"
                        onClick={() => {
                          deleteSearchParams("prod");
                        }}
                      >
                        Clear Search
                      </span>
                    ) : null}
                  </div>
                  <ul className="category__tab">
                    <li
                      className={`category__tabItem ${
                        (!searchParams.get("filter") ||
                          searchParams.get("filter") === "all") &&
                        "active"
                      }`}
                      onClick={() => {
                        updateSearchParams("filter", "all");
                        updateSearchParams("page", "1");
                      }}
                    >
                      All
                    </li>
                    <li
                      className={`category__tabItem ${
                        searchParams.get("filter") === "popular" && "active"
                      }`}
                      onClick={() => {
                        updateSearchParams("filter", "popular");
                        updateSearchParams("page", "1");
                      }}
                    >
                      Popular
                    </li>
                    <li
                      className={`category__tabItem ${
                        searchParams.get("filter") === "newest" && "active"
                      }`}
                      onClick={() => {
                        updateSearchParams("filter", "newest");
                        updateSearchParams("page", "1");
                      }}
                    >
                      Newest
                    </li>
                    <li
                      className={`category__tabItem ${
                        searchParams.get("filter") === "highest-price" &&
                        "active"
                      }`}
                      onClick={() => {
                        updateSearchParams("filter", "highest-price");
                        updateSearchParams("page", "1");
                      }}
                    >
                      Higest - Lowest
                    </li>
                    <li
                      className={`category__tabItem ${
                        searchParams.get("filter") === "lowest-price" &&
                        "active"
                      }`}
                      onClick={() => {
                        updateSearchParams("filter", "lowest-price");
                        updateSearchParams("page", "1");
                      }}
                    >
                      Lowest - Higest
                    </li>
                  </ul>
                  <div className="category__tab--mobile">
                    <div
                      className="category__items category__items1"
                      onClick={() => setClickFilter(true)}
                    >
                      <p>filter</p>
                    </div>
                    <div className="category__items category__items2">
                      <div className="filters">
                        <select
                          onChange={(e) => {
                            updateSearchParams("filter", e.target.value);
                            updateSearchParams("page", "1");
                          }}
                        >
                          <option value="all">All</option>
                          <option value="popular">Popular</option>
                          <option value="newest">Newest</option>
                          <option value="lowest-price">Lowest - Highest</option>
                          <option value="highest-price">Higest - Lowest</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="category__products">
                    {isloading &&
                      new Array(20)
                        .fill(null)
                        .map((_, i) => <ProdCategoryLoader key={i} />)}
                    {!isloading &&
                      !networkError &&
                      prods
                        ?.filter((prod) => {
                          const activeBrands =
                            brands &&
                            brands
                              .filter((brnd) => brnd.active)
                              .map((brnd) => brnd.id);

                          if (activeBrands?.length)
                            return activeBrands.includes(prod.brand_id);
                          return true;
                        })
                        .map((itm) => (
                          <ProdCategory
                            data={itm}
                            key={itm.id}
                            setCartMessage={setCartMessage}
                            setCartStatus={setCartStatus}
                            setShowCartNotify={setShowCartNotify}
                            closePopup={closePopup}
                            setClosePopup={setClosePopup}
                          />
                        ))}
                  </div>
                  {!isloading && networkError && (
                    <Empty
                      emptyTitle={cartMessage}
                      emptySubTitle="Something went wrong! TRY AGAIN.."
                      network={true}
                    />
                  )}
                  {searchParams.get("prod") === null &&
                    !prods?.length &&
                    !isloading &&
                    !networkError && (
                      <Empty emptyTitle="No Item found!" emptySubTitle="" />
                    )}
                  {searchParams.get("prod") !== null &&
                    !prods?.length &&
                    !isloading &&
                    !networkError && (
                      <Empty emptyTitle="No Item found!" emptySubTitle="" />
                    )}

                  {!isloading && prods.length && !lastPage ? (
                    <div className="paginate">
                      {links?.map((link, i) => (
                        <span
                          className={`${link.active ? "active__page" : ""} ${
                            i === 0 || i === links.length - 1
                              ? "display__none"
                              : ""
                          }`}
                          key={i}
                          onClick={() => {
                            updateSearchParams("page", link.label);
                          }}
                        >
                          {link.label}
                        </span>
                      ))}
                    </div>
                  ) : null}

                  {!isloading &&
                  prods.length &&
                  lastPage !== 1 &&
                  !networkError ? (
                    <div className="paginate">
                      {links?.map((link, i) => (
                        <span
                          className={`${link.active ? "active__page" : ""} ${
                            i === 0 || i === links.length - 1
                              ? "display__none"
                              : ""
                          }`}
                          key={i}
                          onClick={() => {
                            updateSearchParams("page", link.label);
                          }}
                        >
                          {link.label}
                        </span>
                      ))}
                    </div>
                  ) : null}

                  {prodNotFoundBanner && (
                    <div className="category__product-not-found">
                      <img
                        id="open_live_chat"
                        src={prodNotFoundBanner}
                        alt="contact us"
                        className="not-found-banner"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BestBrand />
      </Layout>
      {showCartNotify && (
        <CartNotification
          cartMessage={cartMessage}
          cartStatus={cartStatus}
          setShowCartNotify={setShowCartNotify}
        />
      )}
      {closePopup && (
        <SignLogin
          setClosePopup={setClosePopup}
          closePopup={closePopup}
          myCurrentLocation={myCurrentLocation}
        />
      )}
    </>
  );
};

export default Category;

const FilterLoader = () => {
  return (
    <div className="filterBrand placeholder">
      <ul className="filterBrand__listing placeholder">
        <li className="filterBrand__item">
          <div className="form_group">
            <label htmlFor="null">name</label>
          </div>
        </li>
        <li className="filterBrand__item">
          <div className="form_group">
            <label htmlFor="null">name</label>
          </div>
        </li>
        <li className="filterBrand__item">
          <div className="form_group">
            <label htmlFor="null">name</label>
          </div>
        </li>
        <li className="filterBrand__item">
          <div className="form_group">
            <label htmlFor="null">name</label>
          </div>
        </li>
        <li className="filterBrand__item">
          <div className="form_group">
            <label htmlFor="null">name</label>
          </div>
        </li>
        <li className="filterBrand__item">
          <div className="form_group">
            <label htmlFor="null">name</label>
          </div>
        </li>
        <li className="filterBrand__item">
          <div className="form_group">
            <label htmlFor="null">name</label>
          </div>
        </li>
        <li className="filterBrand__item">
          <div className="form_group">
            <label htmlFor="null">name</label>
          </div>
        </li>
        <li className="filterBrand__item">
          <div className="form_group">
            <label htmlFor="null">name</label>
          </div>
        </li>
        <li className="filterBrand__item">
          <div className="form_group">
            <label htmlFor="null">name</label>
          </div>
        </li>
      </ul>
    </div>
  );
};
