import { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateShippingFee, resetShippingFee } from "../store/slices/cartSlice";
import {
  setDeliverToAddress,
  setDefaultAddress,
  removeDeliverToAddress,
  removeNewAddedDeliveryAddress,
  setAllAddresses,
} from "../store/slices/addressSlice";
import { axiosInstance, baseUrl, currency_formatter } from "../libries";

//component
import Layout from "../containers/Layout";
import Breadcrumb from "../components/Breadcrumb";
import ShippingAddress from "../components/ShippingAddress";
import AvailableAddresses from "../components/AvailableAddresses";
import CartNotification from "../components/CartNotification";
import InsufficientBalance from "../components/InsufficientBalance";

//icons or images
import wallet from "../assets/imgs/icons/wallet-icon.png";
import truck from "../assets/imgs/icons/truck.png";
import OrderSummary from "../components/OrderSummary";

function ShippingMethodLoading () {
  return Array.from({length: 2}, (_, i) => (
    <li className="shipping__method__list">
          <div>
            <div className="remove__border">
              <div className="deliveries">
                <div className="placeholder">
                  <img
                    src="../assets/imgs/icons/truck.png"
                    alt=""
                    className="truck"
                  />
                </div>
                <div className="shipping__picks">
                  <div className="shipping__carrier">
                    <span className="type placeholder">
                      carrier name
                    </span>
                  </div>
                  <p className="fee">
                    <span className="fee__bold placeholder">
                      Delivery fee: ₦5000.00
                    </span>
                  </p>
                  <p className="datee">
                    <span className="datee__bold placeholder">
                      Delivery time: within 5 days
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
  ))
}

const Checkout = () => {
  const placeAnOrderRef = useRef();
  const dispatch = useDispatch();

  // getting state from global store to checkout an order
  const { shippingFee, coupon, total_products, coupon_amount_percent } =
    useSelector((state) => state.cart);
  const cartItems = useSelector((state) => state.cart.carts);
  const totalFee = useSelector((state) => state.cart.totalOrderFee);
  const walletBal = useSelector((state) => state.auth.userDetails.wallet);
  const userBankDetails = useSelector((state) => state.auth.userDetails.bank);
  const {
    deliverToAddress,
    newAddedAddressForDelivery,
    defaultAddress,
    allAddresses,
  } = useSelector((state) => state.address);

  const token = useSelector((state) => state.auth.accessToken);

  // setting loading states here
  const [loading, setLoading] = useState(true);
  const [loadingShipping, setLoadingShipping] = useState(false);

  // state to show the notification on checkout page
  const [showCartNotify, setShowCartNotify] = useState(false);
  const [cartStatus, setCartStatus] = useState("");
  const [cartMessage, setCartMessage] = useState("");
  const [shippingMethodMode, setShippingMethodMode] = useState(null);

  // use state to store shipping types
  const [shippingTypes, setShippingTypes] = useState(null);

  const [publicTransportText, setPublicTransportText] = useState("");

  // getting the shippment methods here

  // state to get all shipping methods
  const [shippmentMethods, setShippmentMethods] = useState(null);

  // state to get selected shipping method
  const [selShippmentMtd, setSelShippmentMtd] = useState(null);

  // logic below sets the payload for checkout
  let allVariation = // checkout cart for product with or without variation
    cartItems?.map((cartItem) => {
      return cartItem.variation_info !== null
        ? {
            product_id: cartItem.id,
            variation: cartItem.variation_info?.map((variatn) => ({
              id: variatn.id,
              quantity: variatn.quantity,
            })),
          }
        : {
            product_id: cartItem.id,
            quantity: cartItem.quantity,
          };
    }) || [];

  // state to store pay with
  const [payWith, setPayWith] = useState(null);

  // state to keep track of the payment method
  const [paymentMtd, setPaymentMtd] = useState(null);

  // showing popup for insufficient balance
  const [insufficient, setInsufficient] = useState(false);

  // state for adding address
  const [allAddress, setAllAddress] = useState(null);

  const [addresses, setAddresses] = useState(null);

  // function to close insufficient popup
  const ClosePopup = () => setInsufficient(false);

  const navigate = useNavigate();

  // checkout page progress bar
  const checkoutSteps = ["checkout", "payment", "review"];

  const [prevStep, setPrevStep] = useState();

  const [checkoutStep, setCheckoutStep] = useState("checkout");

  // state to get shipping methods
  const [shippingMtdCode, setShippingMtdCode] = useState("");

  const [shippingMtd, setShippingMtd] = useState(null);

  const address = deliverToAddress ? deliverToAddress : defaultAddress;

  // side effect for checkout progress steps
  useEffect(() => {
    if (checkoutStep === "checkout") {
      setPrevStep(0);
    } else if (checkoutStep === "payment") {
      setPrevStep(1);
    } else {
      setPrevStep(2);
    }
  }, [checkoutStep]);

  // proceed to pay button to finally place an order
  const handleSelectPaymentMtd = (mtd) => {
    setPaymentMtd(mtd);
  };

  const [balAbleToOrder, setBalAbleToOrder] = useState(false);

  const [makingOrder, setMakingOrder] = useState(false);

  // state to show add shipping address
  const [shippingAddress, setShippingAddress] = useState(false);

  // state to show choose address pop up
  const [chooseAddress, setChooseAddress] = useState(false);

  // state to save address
  const [addNewAddress, setAddNewAddress] = useState({
    is_default: 0,
    first_name: "",
    last_name: "",
    phone_number: "",
    address: "",
    country: 161, // default value
    state: null,
    lg: null,
    city: "",
    address_type_id: 1, // default
  });

  // function to pay for order in checkout
  const handleOrders = (payMtd) => {
    if (!balAbleToOrder) return;

    placeAnOrderRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Processing...`;
    placeAnOrderRef.current.setAttribute("disabled", "disabled");

    setMakingOrder(true);
    const orderData = {
      product_orders: allVariation,
      address_type_id: deliverToAddress.address_type_id,
      country_id: 161, // default value
      state_id: deliverToAddress.state_id || deliverToAddress.state,
      lg_id: deliverToAddress.lg_id || deliverToAddress.lg,
      use_as_default: deliverToAddress.is_default,
      shipping_method_id: shippingMethodMode,
      address: deliverToAddress.address,
      phone: deliverToAddress.phone_number,
      payment_code: paymentMtd,
      sub_total: total_products,
      shipping: shippingFee,
      total: Number(totalFee),
      delivery_address_id: deliverToAddress.id,
      carrier_name:
        shippingMethodMode === "carrier" ? selShippmentMtd.carrier_name : null,
      carrier_id:
        shippingMethodMode === "carrier"
          ? selShippmentMtd.carrier_reference
          : "",
      amount:
        shippingMethodMode === "carrier"
          ? selShippmentMtd.amount.toFixed(2)
          : null,
      carrier_logo:
        shippingMethodMode === "carrier" ? selShippmentMtd.carrier_logo : null,
      delivery_time:
        shippingMethodMode === "carrier"
          ? selShippmentMtd.delivery_time
          : shippingMtd.delivery_time,
      rate_id: "",
      coupon_code: coupon_amount_percent ? coupon : "",
    };

    axiosInstance(token)
      .post("/orders/checkout", orderData)
      .then((resp) => {
        if (payMtd !== "flutterwave") {
          const dataResp = resp.data.data.orders;
          navigate(`/thank-you?order_code=${dataResp.order_code}`);
        }

        if (payMtd === "flutterwave") {
          if (!resp.data.status && resp.data.link)
            window.location.href = resp.data.link;
        }
      })
      .catch((error) => {
        // trying to set the notification for error when placing an order here'
        setCartStatus("error");
        if (error.code === "ERR_BAD_REQUEST") {
          setCartMessage(error.response.data.message);
        } else {
          setCartMessage(error.message);
        }
        setShowCartNotify(true);
      })
      .finally(() => {
        placeAnOrderRef.current.removeAttribute("disabled", "disabled");
        placeAnOrderRef.current.innerHTML = "Proceed to Pay";
        setMakingOrder(false);
      });
  };

  // side effect to set payment button active
  useEffect(() => {
    if (!paymentMtd) setBalAbleToOrder(false);
    if (paymentMtd === "flutterwave" || paymentMtd === "pay-on-pickup")
      setBalAbleToOrder(true);

    if (paymentMtd === "wallets") {
      const checkingToPlaceOrder = walletBal.available_balance > totalFee;
      if (checkingToPlaceOrder) {
        setBalAbleToOrder(true);
      } else {
        setBalAbleToOrder(false);
        setInsufficient(true);
      }
    }
  }, [totalFee, paymentMtd, walletBal.available_balance]);

  // using the normal axios function here
  useEffect(() => {
    axiosInstance()
      .get(`/payments/method`)
      .then((resp) => {
        setPayWith(resp.data.data.payment_methods);
      })
      .catch((error) => {
        setCartStatus("error");
        if (error.code === "ERR_BAD_REQUEST") {
          setCartMessage(error.response.data.message);
        } else {
          setCartMessage(error.message);
        }
        setShowCartNotify(true);
      });
  }, []);

  // want to chain fetching addresses, default address and shipping types here

  // getting all addresses
  useEffect(() => {
    axiosInstance(token)
      .get("/accounts/delivery/addresses")

      .then((resp) => {
        const addressesFetched = resp.data.data.delivery_addresses.data;
        setAddresses(addressesFetched);
        dispatch(setAllAddresses(addressesFetched));
        setLoading(false);

        if (!addressesFetched.length) {
          dispatch(removeDeliverToAddress());
          dispatch(removeNewAddedDeliveryAddress());
        }

        return axiosInstance(token).get(
          `${baseUrl}/accounts/default/delivery/address`
        );
      })
      .then(async (resp) => {
        const defaultAdd = resp.data.data.delivery_addresses;
        dispatch(setDefaultAddress(defaultAdd));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setCartStatus("error");
        if (err.code === "ERR_BAD_REQUEST") {
          setCartMessage(err.response.data.message);
        } else {
          setCartMessage(err.message);
        }
        setShowCartNotify(true);
      })
      .finally(() => setLoading(false));
  }, [dispatch, token]);

  const [states, setStates] = useState(null);

  // function to get state
  useEffect(() => {
    axiosInstance()
      .get(`/locations/states`)
      .then((resp) => {
        setStates(resp.data.data.states);
      })
      .catch((error) => {
        setCartStatus("error");
        if (error.code === "ERR_BAD_REQUEST") {
          setCartMessage(error.response.data.message);
        } else {
          setCartMessage(error.message);
        }
        setShowCartNotify(true);
      });
  }, []);

  // getting shipping types and refetching upon every address change
  useEffect(() => {
    if (address && !loading) {
      setLoadingShipping(true);
      setSelShippmentMtd(null);
      setShippingMtdCode("");
      setPaymentMtd(null);
      dispatch(resetShippingFee());

      axiosInstance()
        .get(`${baseUrl}/shipment/shipping/types`)
        .then((resp) => {
          const respShippingTypes = resp.data.data;
          setShippingTypes(respShippingTypes.shipping);
          setPublicTransportText(respShippingTypes.text);
        })
        .catch((error) => {
          setCartStatus("error");
          if (error.code === "ERR_BAD_REQUEST") {
            setCartMessage(error.response.data.message);
          } else {
            setCartMessage(error.message);
          }
          setShowCartNotify(true);
        })
        .finally(() => {
          setLoadingShipping(false);
        });
    }
  }, [address, dispatch, loading]);

  // side effect to get shipping rate for door delivery
  useEffect(() => {
    if (!address) return () => {};

    const stateName = states?.find((st) => st.id === address?.state_id);

    if (!stateName) return () => {};
    let itemToOrder = [];

    cartItems?.map((cartItem) =>
      cartItem.variation_info === null
        ? itemToOrder.push({
            description: cartItem.name,
            name: cartItem.name,
            currency: "NGN",
            value: parseFloat(cartItem.price),
            quantity: cartItem.quantity,
            weight: cartItem.weight,
          })
        : cartItem.variation_info?.map((withVar) =>
            itemToOrder.push({
              description: cartItem.description,
              name: cartItem.name,
              currency: "NGN",
              value: parseFloat(withVar.amount),
              quantity: withVar.quantity,
              weight: cartItem.weight,
            })
          )
    );

    if (itemToOrder.length === 0) return () => {};

    const shipping = {
      pickup_address: {
        line1: "Bodija",
        line2: "Bodija",
        city: "Ibadan",
        state: "Oyo",
        country: "NG",
        first_name: "ShopNig",
        last_name: "ShopNig",
        phone: "+2348109967572",
      },
      delivery_address: {
        line1: address.address,
        line2: address.address,
        city: address.city,
        state: stateName.name,
        country: "NG",
        first_name: address.first_name,
        last_name: address.last_name,
        phone: address.phone_number,
      },
      parcel: {
        description: "Order Details",
        weight_unit: "kg",
        items: itemToOrder,
        metadata: {},
      },
      carrier_id: "CA-71017347351",
      currency: "NGN",
    };

    axiosInstance(token)
      .post("/shipment/rate", shipping)
      .then((resp) => {
        const shippmentResp = resp.data.data;
        setShippmentMethods(shippmentResp);
      })
      .catch((error) => {
        setShippmentMethods(null);
        setCartStatus("error");
        if (error.code === "ERR_BAD_REQUEST") {
          setCartMessage(error.response.data.message);
        } else {
          setCartMessage(error.message);
        }
      });
  }, [address, states, token, cartItems]);

  // function to get shipping methos selected
  const handleShippingMethod = (mtds) => {
    setShippingMtdCode(mtds.code);
    setShippingMtd(mtds);
    setShippingMethodMode(mtds.code);

    const getMethod = shippingTypes.find((mt) => mt.code === mtds.code);

    if (mtds.code !== "carrier") {
      dispatch(updateShippingFee(Number.parseInt(getMethod.delivery_fee)));
    }
  };

  // side effect for checkout progress check
  useEffect(() => {
    if (shippingMtdCode && !paymentMtd) {
      setCheckoutStep("payment");
    }

    if (shippingMtdCode && paymentMtd) {
      setCheckoutStep("review");
    }
  }, [shippingMtdCode, paymentMtd]);

  // function for handling selected shipping method
  const handleSelMethod = (mtdShip) => {
    const getShip = shippmentMethods.find((mt) => mt.carrier_name === mtdShip);

    setSelShippmentMtd(getShip);
    setShippingMtdCode(mtdShip);

    // dispatching shipping fee

    dispatch(updateShippingFee(getShip.amount.toFixed(2)));

    // dispatching shipping method mode
    setShippingMethodMode("carrier");
  };

  const showShippingAddress = () => {
    setShippingAddress(true);
  };

  const matchAddress =
    newAddedAddressForDelivery && allAddresses
      ? allAddresses.find(
          (address) => address.id === newAddedAddressForDelivery.id
        )
      : null;

  // restructuring useEffect to set deliverToAddress
  useEffect(() => {
    if (matchAddress) {
      dispatch(setDeliverToAddress(matchAddress));
    } else if (addresses) dispatch(setDeliverToAddress(addresses[0]));
    else dispatch(setDeliverToAddress(null));
  }, [matchAddress, dispatch, addresses]);

  const [selPayment, setSelPayment] = useState(payWith);

  // side effect for handling paying on pickup
  useEffect(() => {
    if (shippingMtdCode !== "pick-up") {
      setSelPayment(payWith?.filter((pay) => pay.default === 1));
    } else setSelPayment(payWith);
  }, [shippingMtdCode, payWith]);

  useEffect(() => {
    document.title = "ShopNig - Checkout";
  }, []);

  return (
    <>
      <Layout>
        <Breadcrumb name="Product detail >" />
        <div className="container">
          <div className="checkout__container">
            <div className="checkout">
              <div className="checkout__body">
                <div className="">
                  <h3 className="checkout__text">Checkout</h3>
                </div>
                <div className="processes show__processes__up">
                  <div className="processes__list">
                    {checkoutSteps?.map((step, index) => (
                      <div
                        key={index}
                        className={`${step === checkoutStep ? "active" : ""}
                            ${index < prevStep ? "checked" : ""}
                        } processes__list__item add__margin`}
                      >
                        <div className="progress__count"></div>
                        <p
                          className={`${
                            step === checkoutStep
                              ? "active__label"
                              : "inactive__label"
                          } progress__label`}
                        >
                          {step}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="personal__detail">
                <div className="personal__body">
                  <div className="delivery">
                    <h1 className="deliver">Deliver to:</h1>
                    <div
                      className={`${
                        shippingAddress ? "disabled__text" : ""
                      } add__details`}
                      onClick={() => showShippingAddress()}
                    >
                      <ion-icon name="add-outline"></ion-icon>
                      <span>Add another shipping details</span>
                    </div>
                    <ShippingAddress
                      shipping={shippingAddress}
                      setDeliveryAddress={setShippingAddress}
                      setAddNewAddress={setAddNewAddress}
                      addNewAddress={addNewAddress}
                      setShowAlert={setShowCartNotify}
                      setAlertStatus={setCartStatus}
                      setAlertMsg={setCartMessage}
                      setAllAddress={setAllAddress}
                    />
                    {loading && (
                      <div className="loading__spin__address">
                        <span className="loader"></span>
                      </div>
                    )}
                    {!loading && (
                      <div className="deliver__part">
                        {!defaultAddress && !deliverToAddress && (
                          <div>
                            <p className="infos">
                              No Default Address Added yet
                            </p>
                          </div>
                        )}
                        {deliverToAddress && (
                          <div className="deliver_wrap">
                            <h2 className="details__title">Address</h2>
                            <div className="deliver__info">
                              <div>
                                {deliverToAddress && (
                                  <p className="infos">
                                    {deliverToAddress.first_name +
                                      " " +
                                      deliverToAddress.last_name}
                                  </p>
                                )}
                                {!deliverToAddress.first_name &&
                                  !deliverToAddress.last_name &&
                                  !deliverToAddress.address &&
                                  !deliverToAddress.phone_number && (
                                    <p className="infos">
                                      No Address Added yet
                                    </p>
                                  )}
                              </div>
                              <div>
                                <p className="infos">
                                  {deliverToAddress &&
                                    deliverToAddress.phone_number}
                                </p>
                              </div>
                              <div className="adrs">
                                <address className="address">
                                  {deliverToAddress && deliverToAddress.address}
                                </address>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="choose">
                          <button
                            className={`${
                              addresses?.length > 1 || allAddress?.length > 1
                                ? "active__change__checkout"
                                : "disabled__checkout__address"
                            } choose__btn`}
                            onClick={() => setChooseAddress(true)}
                          >
                            Change Address
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {!loading && !deliverToAddress ? null : (
                    <>
                      {loadingShipping && (
                        <div className="loading__spin__address">
                          <span className="loader"></span>
                        </div>
                      )}
                      {!loadingShipping && shippingTypes && (
                        <div className="shipping__methods">
                          <h3 className="shipping__select">
                            Select Shipping Method
                          </h3>
                          <div className="shipping__part">
                            {shippingTypes?.map((shipMtd) => (
                              <label htmlFor={shipMtd.code} key={shipMtd.id}>
                                <div className="shipping__part__types-option">
                                  <div
                                    className={`${
                                      (shippingMtdCode === "carrier" &&
                                        shipMtd.code === "carrier" &&
                                        !selShippmentMtd) ||
                                      (shippingMtdCode === "carrier" &&
                                        shipMtd.code === "carrier" &&
                                        selShippmentMtd)
                                        ? "border__remove"
                                        : ""
                                    } shipping__part__option`}
                                  >
                                    <div className="deliveries">
                                      <div className="">
                                        {shipMtd.code !== "carrier" &&
                                          shipMtd.code !== selShippmentMtd && (
                                            <img
                                              // src={shipMtd.img}
                                              src={truck}
                                              alt={shipMtd.name}
                                              className="truck"
                                            />
                                          )}
                                        {shipMtd.code === "carrier" && (
                                          <img
                                            src={
                                              selShippmentMtd
                                                ? selShippmentMtd.carrier_logo
                                                : truck // "truck" here will be replaced by shipping type image from the endpoint
                                            }
                                            alt={shipMtd.name}
                                            className="truck"
                                          />
                                        )}
                                      </div>
                                      <div className="shipping__picks">
                                        <div className="shipping__carrier">
                                          <h3 className="type">
                                            {shipMtd.name}
                                          </h3>
                                        </div>
                                        {!selShippmentMtd &&
                                          shipMtd.code === "carrier" && (
                                            <>
                                              <p>Select Carrier</p>
                                              <p
                                                style={{ visibility: "hidden" }}
                                              >
                                                Carrier method
                                              </p>
                                            </>
                                          )}
                                        {selShippmentMtd &&
                                          shipMtd.code === "carrier" && (
                                            <>
                                              <p className="fee">
                                                {selShippmentMtd?.carrier_name}{" "}
                                                <span className="fee__bold">
                                                  (₦{" "}
                                                  {selShippmentMtd?.amount.toFixed(
                                                    2
                                                  )}
                                                  )
                                                </span>
                                              </p>
                                              <p className="datee">
                                                Delivery Time: &nbsp;&nbsp;
                                                <span className="datee__bold">
                                                  {
                                                    selShippmentMtd?.delivery_time
                                                  }
                                                </span>
                                              </p>
                                            </>
                                          )}
                                        {shipMtd.code !== "carrier" && (
                                          <>
                                            <p className="fee">
                                              Delivery fee: &nbsp;&nbsp;
                                              <span className="fee__bold">
                                                ₦ {shipMtd.delivery_fee}
                                              </span>
                                            </p>
                                            <p className="datee">
                                              Delivery Time: &nbsp;&nbsp;
                                              <span className="datee__bold">
                                                {shipMtd.delivery_time}
                                              </span>
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    {selShippmentMtd &&
                                      shipMtd.code === "carrier" && (
                                        <p
                                          onClick={(e) => {
                                            setShippingMtdCode("carrier");
                                            e.stopPropagation();
                                          }}
                                          className="change__carrier"
                                        >
                                          Change
                                        </p>
                                      )}
                                    <div className="deliveries__iconss">
                                      <input
                                        className="first__radio"
                                        type="radio"
                                        id={shipMtd.code}
                                        name="shippingMtdCode"
                                        value={shipMtd.code}
                                        onChange={() =>
                                          handleShippingMethod(shipMtd)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={`${
                                      shipMtd.code === "public-transport" &&
                                      shippingMethodMode === "public-transport"
                                        ? "show-transport-text"
                                        : "hide-transport-text"
                                    } shipping__part__public-transport"`}
                                  >
                                    <p className="shipping-public-text">
                                      <span className="shipping-public-text__note">
                                        Note:
                                      </span>
                                      {publicTransportText}
                                    </p>
                                  </div>
                                </div>

                                {((shippingMtdCode === "carrier" &&
                                  shipMtd.code === "carrier" &&
                                  !selShippmentMtd) ||
                                  (shippingMtdCode === "carrier" &&
                                    shipMtd.code === "carrier" &&
                                    selShippmentMtd)) && (
                                  <ul>
                                    {!shippmentMethods ? 
                                    <ShippingMethodLoading /> :
                                    shippmentMethods.map((mtds, index) => (
                                      <li
                                        className="shipping__method__list"
                                        key={index}
                                      >
                                        <div
                                          htmlFor={mtds.carrier_name}
                                          key={index}
                                          onClick={() =>
                                            handleSelMethod(mtds.carrier_name)
                                          }
                                        >
                                          <div className="remove__border">
                                            <div className="deliveries">
                                              <div className="">
                                                <img
                                                  src={mtds.carrier_logo}
                                                  alt="carrier logo"
                                                  className="truck"
                                                />
                                              </div>
                                              <div className="shipping__picks">
                                                <div className="shipping__carrier">
                                                  <h3 className="type">
                                                    {mtds.carrier_name}
                                                  </h3>
                                                </div>
                                                <p className="fee">
                                                  Delivery fee: &nbsp;&nbsp;
                                                  <span className="fee__bold">
                                                    ₦ {mtds.amount.toFixed(2)}
                                                  </span>
                                                </p>
                                                <p className="datee">
                                                  Delivery Time: &nbsp;&nbsp;
                                                  <span className="datee__bold">
                                                    {mtds.delivery_time}
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="deliveries__iconss">
                                              <input
                                                className="first__radio"
                                                type="radio"
                                                id={mtds.carrier_name}
                                                name="shippingMtdCode"
                                                value={mtds.carrier_name}
                                                checked={
                                                  selShippmentMtd?.carrier_name ===
                                                  mtds.carrier_name
                                                }
                                                onChange={() => {}}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </label>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {/* showing the payment type */}

                  {shippingMtdCode && shippingMtdCode !== "carrier" && (
                    <>
                      <div className="shipping__methods">
                        <h3 className="shipping__select">Pay with:</h3>
                        <div className="shipping__part">
                          {selPayment?.map((mtd) => (
                            <label htmlFor={mtd.id} key={mtd.id}>
                              <div className="payment__option">
                                <div className="payments">
                                  <div className="">
                                    <img
                                      src={wallet}
                                      alt=""
                                      className="wallet"
                                    />
                                  </div>
                                  <div className="payments__pick">
                                    <div className="payments__pays">
                                      <div>
                                        <h3 className="payments__mode">
                                          {mtd.name}
                                        </h3>
                                      </div>
                                      <div>
                                        {mtd.name === "ShopNig Wallet" && (
                                          <span className="pays__amount">
                                            {currency_formatter(
                                              walletBal.available_balance
                                            )}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <p className="messg">{mtd.description}</p>
                                  </div>
                                </div>
                                <div className="deliveries__iconss">
                                  <input
                                    type="radio"
                                    id={mtd.id}
                                    name="paymentMtd"
                                    value={mtd.code}
                                    onChange={(e) => e.target.value}
                                    checked={paymentMtd === mtd.code}
                                    onClick={() =>
                                      handleSelectPaymentMtd(mtd.code)
                                    }
                                  />
                                </div>
                              </div>
                            </label>
                          ))}
                        </div>
                      </div>

                      {/* displaying a message below for user with low balance */}
                      {walletBal.available_balance < totalFee &&
                      paymentMtd === "wallets" ? (
                        <div className="low__balance">
                          Your balance is less than your Cart Total, kindly fund
                          your wallet account{" "}
                          <span className="low__balance__acc__details">
                            {userBankDetails.account_number}
                          </span>{" "}
                          or select another means of payment
                        </div>
                      ) : null}
                      {paymentMtd !== null ? (
                        <div className="note">
                          <h4 className="note__text">Note:</h4>
                          {paymentMtd === "wallets" && (
                            <p className="note__notice">
                              A total of{" "}
                              <span className="spaan">
                                {currency_formatter(totalFee)}
                              </span>{" "}
                              will be deducted from your Wallet Balance.
                            </p>
                          )}
                          {paymentMtd === "flutterwave" && (
                            <p className="note__notice">
                              A total of{" "}
                              <span className="spaan">
                                {currency_formatter(totalFee)}
                              </span>{" "}
                              will be processed through Flutterwave (USSD, card,
                              or bank transfer).
                            </p>
                          )}
                          {paymentMtd === "pay-on-pickup" && (
                            <p className="note__notice">
                              A total of{" "}
                              <span className="spaan">
                                {currency_formatter(totalFee)}
                              </span>{" "}
                              will be settled during pickup at our store,
                              located at AB 15 Albarika complex (Arisekola
                              Market) Bodija, Ibadan, Nigeria. Pick-up orders
                              are subject to change in price and are also
                              available for a limited time only.
                            </p>
                          )}
                          {paymentMtd === "flutterwave" ||
                          paymentMtd === "wallets" ? (
                            <p className="note__confirm">
                              Kindly confirm you have sufficient balance for
                              payment. Thank you!
                            </p>
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>

                <div className="processes show__processes__down">
                  <div className="processes__list">
                    {checkoutSteps?.map((step, index) => (
                      <div
                        key={index}
                        className={`${step === checkoutStep ? "active" : ""}
                            ${index < prevStep ? "checked" : ""}
                        } processes__list__item add__margin`}
                      >
                        <div className="progress__count"></div>
                        <p
                          className={`${
                            step === checkoutStep
                              ? "active__label"
                              : "inactive__label"
                          } progress__label`}
                        >
                          {step}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <OrderSummary
                  balAbleToOrder={balAbleToOrder}
                  shippingMtdCode={shippingMtdCode}
                  click={() => handleOrders(paymentMtd)}
                  placeAnOrderRef={placeAnOrderRef}
                  makingOrder={makingOrder}
                  paymentMtd={paymentMtd}
                />
              </div>
            </div>
          </div>
          {insufficient && (
            <InsufficientBalance
              title="ShopnigWallet"
              setInsufficient={setInsufficient}
              close={ClosePopup}
            />
          )}
          {chooseAddress && (
            <AvailableAddresses
              title="Choose from saved address"
              setChangeAddress={setChooseAddress}
            />
          )}
        </div>
      </Layout>
      {showCartNotify && (
        <CartNotification
          cartMessage={cartMessage}
          cartStatus={cartStatus}
          setShowCartNotify={setShowCartNotify}
        />
      )}
    </>
  );
};

export default Checkout;
